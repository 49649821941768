<template>
    <tooltip 
        v-if="model.hasFirstYearOffer"
        class="annual-fee-tooltip" 
        :tooltip-text="$translate('annual_fee_offer_tooltip', '')" 
    />
</template>

<script>
import Tooltip from '@/components/features/Tooltip.vue';
export default {
    components: {
        Tooltip
    },
    props: {
        model: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>
<style lang="scss" scoped>
.annual-fee-tooltip {
    margin-left: 5px;
}
</style>